import React, { useEffect, useRef, useState } from "react";
import { Box, Modal, Snackbar } from "@mui/material";
import style from "./videoDetails.module.scss";
import { back_button, grayHeart, redHeart } from "../../assets/images/index";
import Comment from "../../components/comments/Comment";
import axios from "axios";
import { useUser } from "../../state/UserContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { robo } from "../../assets/images";
import Hls from "hls.js";
import { Helmet } from "react-helmet-async";
import {
  close,
  share_icon,
  share_modal_icon,
  twitter_icon,
} from "../../assets/images";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
} from "react-share";

function VideoDetails() {
  const videoId = useParams().id;

  const navigate = useNavigate();
  const [isFollowed, setIsFollowed] = useState(false);
  const [video, setVideo] = useState(null);
  const [views, setViews] = useState(0);
  const [productFollowers, setProductFollowers] = useState([]);
  const [businessOwnerVideos, setBusinessOwnerVideos] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("Recolyse Video");
  const { userData } = useUser();

  useEffect(() => {
    setTitle(video?.title || "Recolyse Video")
  }, []);

  const isBusinessOwner = JSON.parse(
    window.localStorage.getItem("isBusinessOwner")
  );

  const getBusinessOwnerVideos = async () => {
    if (isBusinessOwner) {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/video-by-business-owner/${userData?._id}`
      );
      const response = await res.json();
      setBusinessOwnerVideos(response.map((video) => video.videoId));
    }
  };

  const formatDate = (inputdate) => {
    const date = new Date(inputdate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  const getVideoInformation = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/video-by-id/${videoId}`
      );
      const response = await res.json();
      if (response) {
        console.log(response);
        setVideo(response);
        return response;
      }
    } catch (error) { }
  }; 
  const generateUrl = (videoId) => {
    // return `${process.env.REACT_APP_CLIENT_URL}/${video?.product.productId}/video/${videoId}`;
    return `${process.env.REACT_APP_API_URL}/video-details`;
  };
  const handleCopyLink = (videoId) => {
    console.log("videoId: ", videoId)
    const url = generateUrl(videoId);
    try {
      navigator.clipboard.writeText(url);
      setMessage("Link copied to clipboard!");
      setOpenSnackbar(true);
    } catch (err) {
      setOpenSnackbar(true);
      setMessage("Failed to copy URL");
      console.error("Failed to copy text: ", err);
    }
  };
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "450px",
    maxHeight: "90%",
    bgcolor: "background.paper",
    borderRadius: "24px",
    boxShadow: "0px 4px 100px 0px rgba(255, 255, 255, 0.24)",
    p: 2.5,
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  };

  const videoRef = useRef(null);

  useEffect(() => {
    const getVideoInformation = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/videos/video-by-id/${videoId}`
        );
        const response = await res.json();
        if (response) {
          setVideo(response);
        }
      } catch (error) {
        console.error("Error fetching video information:", error);
      }
    };

    getVideoInformation();
  }, [videoId]);

  useEffect(() => {
    let hls;

    const initializeHls = () => {
      if (video?.videoURL && Hls.isSupported() && videoRef.current) {
        hls = new Hls();
        hls.loadSource(video.videoURL);
        hls.attachMedia(videoRef.current);
      } else if (videoRef.current && video?.videoURL) {
        videoRef.current.src = video.videoURL; // For native HLS playback
      }
    };

    if (video?.videoURL) {
      initializeHls();
    }

    // Cleanup function to destroy Hls instance when component unmounts or video changes
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [video?.videoURL]);

  const getProductInformation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/${video?.productId}`
      );
      if (response) return response.data;
    } catch (error) {
      return null;
    }
  };

  const followProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/add-follow`,
        { userId: userData?._id, productId: video?.product._id }
      );
      if (response.status === 201) {
        setIsFollowed(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFollowProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/remove-follow`,
        { userId: userData?._id, productId: video?.product._id }
      );
      if (response.status === 201) {
        setIsFollowed(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData) {
      if (video?.product.followers.includes(userData?._id)) {
        setIsFollowed(true);
      } else {
        setIsFollowed(false);
      }
    }
  }, [video?.product.followers]);
  const handleFollow = async () => {
    if (userData) {
      if (video?.product?.followers.includes(userData?._id)) {
        await removeFollowProduct();
      } else {
        await followProduct();
      }
    } else {
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/login");
    }
  };

  const closeHandle = () => {
    const activeItem = localStorage.getItem("activeItem");
    if (activeItem === "My Space") navigate("/my-space");
    else navigate("/");
  };

  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(0);

  useEffect(() => {
    if (video && userData) {
      setLikes(video?.likes?.length || 0);
      setIsLiked(video?.likes?.includes(userData?._id));
    }
  }, [video]);

  const addUpVote = async () => {
    try {
      setIsLiked(true);
      setLikes(likes + 1);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/posts/add-upvote`,
        { userId: userData?._id, videoId: videoId }
      );
      if (response.status === 201) {
        setLikes(response.data.likes.length);
      }
    } catch (error) {
      setIsLiked(false);
      setLikes(likes - 1);
    }
  };

  const removeUpVote = async () => {
    try {
      setIsLiked(false);
      setLikes(likes - 1);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/posts/remove-upvote`,
        { userId: userData?._id, videoId: videoId }
      );
      if (response.status === 201) {
        setLikes(response.data.likes.length);
      }
    } catch (error) {
      setIsLiked(true);
      setLikes(likes + 1);
    }
  };

  async function handleLikeButtonClick() {
    if (userData) {
      if (video.likes?.includes(userData?._id)) {
        await removeUpVote();
      } else {
        await addUpVote();
      }
    } else {
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/login");
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {video?.title ? `${video.title} | Recolyse` : "Recolyse Video"}
        </title>
        <meta
          name="description"
          content={
            video?.description
              ? video.description
              : "Watch innovative startup product demos on Recolyse."
          }
        />

        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content={
            video?.description ||
            "Explore innovative startup products through video demos on Recolyse."
          }
        />
        <meta
          property="og:image"
          content={video?.thumbnail || "https://www.recolyse.com/rc2.png"}
        />
        <meta
          property="og:url"
          content={`https://recolyse.com/${video?.product.name}/video/${videoId}`}
        />
        <meta property="og:type" content="video.other" />

        {/* Twitter Card for Better Sharing */}
        <meta name="twitter:card" content="player" />
        <meta name="twitter:title" content={video?.title || "Recolyse Video"} />
        <meta
          name="twitter:description"
          content={
            video?.description ||
            "Discover innovative startup product videos on Recolyse."
          }
        />
        <meta
          name="twitter:image"
          content={video?.thumbnail || "https://www.recolyse.com/rc2.png"}
        />
        <meta name="twitter:player" content={video?.videoURL} />
        <meta name="twitter:player:width" content="640" />
        <meta name="twitter:player:height" content="360" />

        {/* Schema.org for Google SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            name: video?.title || "Recolyse Video",
            description:
              video?.description || "Discover innovative startup products.",
            thumbnailUrl:
              video?.thumbnail || "https://www.recolyse.com/rc2.png",
            uploadDate: video?.created_at,
            contentUrl: video?.videoURL,
            embedUrl: `https://recolyse.com/${video?.product.name}/video/${videoId}`,
            publisher: {
              "@type": "Organization",
              name: "Recolyse",
              logo: {
                "@type": "ImageObject",
                url: "https://www.recolyse.com/rc2.png",
              },
            },
          })}
        </script>
      </Helmet>
      <div className={style["wrapper"]}>
        <div className={style["close-button-container"]}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={closeHandle}
          >
            <img
              src={back_button}
              alt="close"
              className={style["close-button"]}
            />{" "}
            <span style={{ fontWeight: "500", fontSize: "16px" }}>Back</span>
          </div>
        </div>
        <div className={style["video-page-container"]}>
          <div className={style["section-1"]}>
            <div className={style["video-container"]}>
              <video controls src={video?.videoURL} ref={videoRef}>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className={style["section-2"]}>
            <div className={style["details-container"]}>
              <div className={style["details-video"]}>
                <Link
                  key={video?.productId}
                  className={style.product}
                  to={`/${video?.product?.productId}/${video?.product?._id}`}
                >
                  {video?.product?.image ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${video?.product?.image}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = robo;
                      }}
                      alt="product-logo"
                    ></img>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                    ></svg>
                  )}
                </Link>
                <div className={style["product-information"]}>
                  <Link
                    key={video?.product?._id}
                    className={style.product}
                    to={`/${video?.product?.productId}/${video?.product?._id}`}
                  >
                    <span className={style["product-name"]}>
                      {video?.product?.name}
                    </span>{" "}
                  </Link>
                  <p>
                    <span style={{ color: " #F6552DB5" }}>
                      {video?.category}
                    </span>{" "}
                    {views} views. {formatDate(video?.created_at)}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "18px",
                  gap: "10px",
                }}
              >
                {!businessOwnerVideos.includes(videoId) && (
                  <button
                    className={style["follow-button"]}
                    onClick={handleFollow}
                  >
                    {isFollowed
                      ? "Followed"
                      : productFollowers.includes(userData?._id)
                        ? "Followed"
                        : "Follow"}
                  </button>
                )}
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}>

                  <span
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span>{likes} </span>
                    <button>
                      <img
                        src={isLiked ? redHeart : grayHeart}
                        onClick={handleLikeButtonClick}
                        alt="like button"
                        style={{
                          width: "20px",
                          height: "20px",
                          minWidth: "20px",
                          minHeight: "20px",
                        }}
                      />
                    </button>
                  </span>
                  <button
                    onClick={() => setOpenShareModal(true)}
                    style={{
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      zIndex: "100",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={share_icon} alt="share" />
                  </button>

                  <Modal
                    open={openShareModal}
                    onClose={() => {
                      setOpenShareModal(false);
                    }}
                    className={style["modal"]}
                  >
                    <Box sx={{ ...styleModal }}>
                      <div className={style["sharing-first-row"]}>
                        <div
                          className={style["sharing-first-row-left"]}
                        >
                          <img
                            src={share_modal_icon}
                            alt="share icon"
                          />{" "}
                          <span>Share Video :</span>
                        </div>
                        <button
                          style={{
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => {
                            setOpenShareModal(false);
                          }}
                        >
                          <img src={close} alt="close icon" />
                        </button>
                      </div>

                      <div className={style["copy-container"]}>
                        <span>{generateUrl(video?._id)}</span>
                        <button onClick={() => { handleCopyLink(video?._id) }}>
                          Copy link
                        </button>
                      </div>
                      <div className={style["sharing-btns"]}>
                        <FacebookShareButton
                          url={`${process.env.REACT_APP_API_URL}/video-details`}
                          // url={`https://recolyse.com/${video?.product.productId}/video/${video?._id}`}
                          title={`${video?.product.name} - Recolyse`}
                        >
                          <FacebookIcon borderRadius={100} size={40} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                          url={`${process.env.REACT_APP_API_URL}/video-details`}
                          // url={`https://recolyse.com/${video?.product.productId}/video/${video?._id}`}
                          title={`${video?.product.name} - Recolyse`}
                        >
                          <LinkedinIcon borderRadius={100} size={40} />
                        </LinkedinShareButton>
                        <TwitterShareButton
                          url={`${process.env.REACT_APP_API_URL}/video-details`}
                          // url={`https://recolyse.com/${video?.product.productId}/video/${video?._id}`}
                          title={`${video?.product.name} - Recolyse`}
                        >
                          <img src={twitter_icon} alt="twitter icon" />
                        </TwitterShareButton>
                        <WhatsappShareButton
                          url={`${process.env.REACT_APP_API_URL}/video-details`} 
                          // url={`https://recolyse.com/${video?.product.productId}/video/${video?._id}`}
                          title={`${video?.product.name} - Recolyse`}
                        >
                          <WhatsappIcon borderRadius={100} size={40} />
                        </WhatsappShareButton>
                      </div>
                    </Box>
                  </Modal>

                </div>

              </div>
            </div>
            <span style={{ textDecoration: "underline" }}>Comments</span>
            <Comment
              videoId={videoId}
              productId={video?.product?._id}
            ></Comment>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={2000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              message = {message}
            ></Snackbar>
          </div>

        </div>
      </div>
    </>
  );
}

export default VideoDetails;
