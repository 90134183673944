import { Alert, Dialog, Slide, Snackbar } from "@mui/material";
import Hls from "hls.js";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  arrowDown,
  close,
  comments,
  comments_reel,
  likes,
  likes_reel,
  like_reel,
  redHeart,
  robo,
  twitter_icon,
} from "../../assets/images";
import api from "../../Intercepters/AuthIntercepter";
import { useUser } from "../../state/UserContext";
import style from "./cardGrid.module.scss";
import Comment from "../../components/comments/Comment";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
} from "react-share";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VideoDetailsModal = ({
  open,
  onClose,
  videoData,
  videosData,
  selectedVideoIndex,
  setSelectedVideo,
  setSelectedVideoIndex,
}) => {
  const videoRef = useRef(null);
  const { userData } = useUser();
  const [isFollowed, setIsFollowed] = useState("Follow");
  const [likesValue, setLikesValue] = useState(videoData?.likes);
  const [liked, setLiked] = useState(videoData?.likes.includes(userData?._id));
  const [commentsValue, setCommentsValue] = useState(videoData?.comments);
  const [videoUrl, setVideoUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [startY, setStartY] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [transitionEnabled, setTransitionEnabled] = useState(false);
  const [showIndicator, setShowIndicator] = useState(true);
  const navigate = useNavigate();

  // Optionally, hide the indicator once the user starts scrolling
  useEffect(() => {
    const handleScroll = () => {
      setShowIndicator(false);
    };

    // window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [videoData]);

  useEffect(() => {
    if (userData) {
      setLiked(videoData?.likes.includes(userData?._id));
    }
  }, [videoData, userData]);

  useEffect(() => {
    if (userData) {
      if (videoData?.product.followers.includes(userData?._id)) {
        setIsFollowed("Followed");
      } else {
        setIsFollowed("Follow");
      }
    }
  }, []);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const { data } = await api.get(`/videos/video-url/${videoData._id}`);
        if (data && data.videoUrl) {
          setVideoUrl(data.videoUrl);
          return data.videoUrl;
        }
      } catch (error) {
        console.error("Error fetching video URL:", error);
        return null;
      }
    };
    if (videoData) {
      fetchVideoUrl();
    }
  }, [videoData]);

  useEffect(() => {
    setCommentsValue(videoData?.comments);
    setLikesValue(videoData?.likes);
  }, [videoData]);

  useEffect(() => {
    let hls;
    if (videoUrl && Hls.isSupported() && videoRef.current) {
      hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoRef.current);

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current) {
      videoRef.current.src = videoUrl;
    }
  }, [videoUrl]);

  const updateUrl = () => {
    const url = generateUrl();
    window.history.pushState(null, "", url);
  };

  useEffect(() => {
    updateUrl();
  }, [videoUrl]);

  const generateUrl = () => {
    return `${process.env.REACT_APP_CLIENT_URL}/public/${videoData.product.productId}/video/${videoData._id}`;
    // return `${process.env.REACT_APP_API_URL}/video-details`;
  };

  const handleCopyLink = () => {
    const url = generateUrl();
    try {
      navigator.clipboard.writeText(url);

      setOpenSnackbar(true);
      setMessage("Link copied to clipboard!");
    } catch (err) {
      setOpenSnackbar(true);
      setMessage("Failed to copy URL");
      console.error("Failed to copy text: ", err);
    }
  };

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const deltaY = e.touches[0].clientY - startY;
    setCurrentPosition(deltaY); // Update the position dynamically
    setTransitionEnabled(false); // Disable transition during the swipe
  };

  const handleTouchEnd = () => {
    setShowIndicator(false);
    const threshold = 100; // Minimum swipe distance to trigger a transition

    if (currentPosition > threshold && selectedVideoIndex > 0) {
      handlePrevVideo(); // Swipe down, show the previous video
    } else if (
      currentPosition < -threshold &&
      selectedVideoIndex < videosData.length - 1
    ) {
      handleNextVideo(); // Swipe up, show the next video
    }

    setTransitionEnabled(true); // Enable transition for snapping back
    setCurrentPosition(0); // Reset position
  };

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "ArrowUp") handlePrevVideo();
      if (e.key === "ArrowDown") handleNextVideo();
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [selectedVideoIndex, videosData]);

  // Navigate to the previous video if it exists
  const handlePrevVideo = () => {
    if (selectedVideoIndex > 0) {
      const prevIndex = selectedVideoIndex - 1;
      setSelectedVideo(videosData[prevIndex]);
      setSelectedVideoIndex(prevIndex);
    }
  };

  // Navigate to the next video if it exists
  const handleNextVideo = () => {
    if (selectedVideoIndex < videosData.length - 1) {
      const nextIndex = selectedVideoIndex + 1;
      setSelectedVideo(videosData[nextIndex]);
      setSelectedVideoIndex(nextIndex);
    }
  };

  if (!videoData) return null;

  const followProduct = async () => {
    try {
      if (userData) {
        const response = await api.post(`/products/add-follow`, {
          userId: userData._id,
          productId: videoData?.product._id,
        });
        console.log(userData?._id);
        console.log(videoData?.product._id);
        console.log(response);
        if (response.status === 201) {
          setIsFollowed(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFollowProduct = async () => {
    try {
      if (userData) {
        const response = await api.post(`/products/remove-follow`, {
          userId: userData?._id,
          productId: videoData?.product._id,
        });
        if (response.status === 201) {
          setIsFollowed(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFollow = async () => {
    if (userData) {
      if (isFollowed) {
        await removeFollowProduct();
      } else {
        await followProduct();
      }
    } else {
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/login");
    }
  };

  function formatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num.toString();
  }

  const handleLike = async () => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/videos/Mobile_IOS/likeVideo/${videoData._id}`,
        { userId: userData?._id }
      );
      setLikesValue(response.data.likes);
    } catch (error) {}
  };

  const handleHeartClick = (e) => {
    e.stopPropagation();
    if (userData) {
      handleLike();
      setLiked((prevLiked) => !prevLiked);
    } else {
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/login");
    }
  };

  const updateCommentCount = () => {
    userData && setCommentsValue((prev) => [...prev, {}]);
  };

  const handleCommentDeleted = () => {
    userData && setCommentsValue((prevComments) => prevComments.slice(0, -1));
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        window.history.pushState(null, "", "/");
        onClose();
      }}
      sx={{
        height: "100dvh",
        width: "100%",
        // overflow: "hidden",
      }}
    >
      <button
        onClick={() => {
          window.history.pushState(null, "", "/");
          onClose();
        }}
        style={{
          position: "absolute",
          top: "1.2rem",
          left: "1.2rem",
          backgroundColor: "rgba(84, 84, 84, 0.5)",
          borderRadius: "50%",
          padding: "5px",
          width: "40px",
          height: "40px",
          cursor: "pointer",
          zIndex: "10000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="24"
          height="24"
          viewBox="0 0 50 50"
        >
          <path
            d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
            fill="white"
          ></path>
        </svg>
      </button>

      {showIndicator && (
        <div className={style["scroll-indicator"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
          >
            <path
              fill="none"
              d="M12 5v14m-7-7l7 7 7-7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      <div
        className={style["container-video-details"]}
        style={{
          // overflowY: "scroll",
          scrollSnapType: "y mandatory",
          transform: `translateY(${currentPosition}px)`,
          transition: transitionEnabled ? "transform 0.3s ease" : "none",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className={style["video-container"]}>
          {/* Blurred Background */}
          <div
            className={style["blurred-background"]}
            style={{
              backgroundImage: `url(${videoData?.capture})`,
            }}
          ></div>

          <div className={style["arrows"]}>
            <div className={style["reels-btn"]}>
              <Link
                key={videoData?.productId}
                className={style["product-pic"]}
                to={`/${videoData?.product?.productId}/${videoData?.product?._id}`}
              >
                {videoData?.product?.image ? (
                  <img
                    src={
                      videoData?.product?.image?.includes("uploads")
                        ? `${process.env.REACT_APP_API_URL}/${videoData?.product?.image}`
                        : videoData?.product?.image
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = robo;
                    }}
                    alt="product-logo"
                  ></img>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  ></svg>
                )}
              </Link>

              <div className={style["likes-div"]}>
                <button
                  className={style["heart-btn"]}
                  onClick={handleHeartClick}
                >
                  <svg
                    width="50px"
                    height="50px"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M725.333333 192c-89.6 0-168.533333 44.8-213.333333 115.2C467.2 236.8 388.266667 192 298.666667 192 157.866667 192 42.666667 307.2 42.666667 448c0 253.866667 469.333333 512 469.333333 512s469.333333-256 469.333333-512c0-140.8-115.2-256-256-256z"
                      fill={liked ? "#ff6a55" : "#fff"}
                    />
                  </svg>
                </button>
                <span>{formatNumber(likesValue.length)}</span>
              </div>

              <div className={style["comments-div"]}>
                <button
                  className={style["openModal"]}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  style={{
                    borderRadius: "50%",
                    padding: "5px",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    zIndex: "10000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={comments_reel} alt="comments" />
                </button>
                <span>{formatNumber(commentsValue.length)}</span>
              </div>
            </div>

            <div className={style["arrows-btns"]}>
              {selectedVideoIndex > 0 && (
                <button
                  onClick={handlePrevVideo}
                  style={{
                    zIndex: 10,
                    backgroundColor: "rgba(84, 84, 84, 0.5)",
                    borderRadius: "50%",
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                  >
                    <path
                      d="m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}

              {selectedVideoIndex < videosData?.length - 1 && (
                <button
                  onClick={handleNextVideo}
                  style={{
                    zIndex: 10,
                    backgroundColor: "rgba(84, 84, 84, 0.5)",
                    borderRadius: "50%",
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"
                      fill="white"
                    />
                  </svg>{" "}
                </button>
              )}
            </div>
          </div>

          <div className={style["video-details"]}>
            <span className={style["video-details-product"]}>
              {videoData?.product.name}
            </span>
            <span>{videoData?.title}</span>
          </div>

          {/* Video Element */}
          <video
            className={style["video"]}
            controls
            autoPlay
            playsInline
            ref={videoRef}
            onClick={handleVideoClick}
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          sx={{
            marginTop: 20,
            // overflow: "hidden",
          }}
        >
          <div className={style["video-infoo"]}>
            <div className={style["product-info"]}>
              <Link
                key={videoData?.productId}
                className={style.product}
                to={`/${videoData?.product?.productId}/${videoData?.product?._id}`}
              >
                {videoData?.product?.image ? (
                  <img
                    src={
                      videoData?.product?.image?.includes("uploads")
                        ? `${process.env.REACT_APP_API_URL}/${videoData?.product?.image}`
                        : videoData?.product?.image
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = robo;
                    }}
                    alt="product-logo"
                  ></img>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  ></svg>
                )}
                <div className={style["name"]}>
                  <span className={style["product-name"]}>
                    {videoData?.product?.name}
                  </span>
                  <span style={{ color: " #F6552DB5" }}>
                    {videoData?.category}
                  </span>
                </div>
              </Link>
              <button className={style["follow-button"]} onClick={handleFollow}>
                {isFollowed}
              </button>
            </div>

            <div className={style["values"]}>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <button onClick={handleHeartClick}>
                    <img src={likes} alt="likes" className={style["likes"]} />
                  </button>
                  <span>{formatNumber(likesValue.length)}</span>
                </div>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <img
                    src={comments}
                    alt="comments"
                    className={style["likes"]}
                  />
                  <span>{formatNumber(commentsValue.length)}</span>
                </div>
              </div>

              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <FacebookShareButton
                  url={`${process.env.REACT_APP_API_URL}/video-details`}
                  // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData._id}`}
                  title={`${videoData?.product?.name} - Recolyse`}
                >
                  <FacebookIcon borderRadius={100} size={35} />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_API_URL}/video-details`}
                  // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData._id}`}
                  title={`${videoData?.product?.name} - Recolyse`}
                >
                  <LinkedinIcon borderRadius={100} size={35} />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_API_URL}/video-details`}
                  // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData?._id}`}
                  title={`${videoData?.product?.name} - Recolyse`}
                >
                  <img src={twitter_icon} alt="twitter icon" width={37} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`${process.env.REACT_APP_API_URL}/video-details`}
                  // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData._id}`}
                  title={`${videoData?.product?.name} - Recolyse`}
                >
                  <WhatsappIcon borderRadius={100} size={35} />
                </WhatsappShareButton>
              </div>
            </div>

            <div className={style["copy-container"]}>
              <span>{generateUrl()}</span>
              <button onClick={handleCopyLink}>Copy link</button>
            </div>

            <span
              style={{
                textDecoration: "underline",
                fontWeight: "600",
              }}
            >
              Comments
            </span>
            <Comment
              videoId={videoData._id}
              productId={videoData?.product?._id}
              onCommentAdded={updateCommentCount}
              onCommentDeleted={handleCommentDeleted}
            ></Comment>
          </div>
        </Dialog>

        <div className={style["video-info"]}>
          <div className={style["product-info-wrapper"]}>
            <div className={style["product-info"]}>
              <Link
                key={videoData?.productId}
                className={style.product}
                to={`/${videoData?.product?.productId}/${videoData?.product?._id}`}
              >
                {videoData?.product?.image ? (
                  <img
                    src={
                      videoData?.product?.image?.includes("uploads")
                        ? `${process.env.REACT_APP_API_URL}/${videoData?.product?.image}`
                        : videoData?.product?.image
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = robo;
                    }}
                    alt="product-logo"
                  ></img>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  ></svg>
                )}
                <div className={style["name"]}>
                  <span className={style["product-name"]}>
                    {videoData?.product?.name}
                  </span>
                  <span style={{ color: " #F6552DB5" }}>
                    {videoData?.category}
                  </span>
                </div>
              </Link>
              {videoData?.product?.business_owner._id != userData?._id && (
                <button
                  className={style["follow-button"]}
                  onClick={handleFollow}
                >
                  {isFollowed}
                </button>
              )}
            </div>
            <span className={style["video-title"]}>{videoData?.title}</span>
          </div>

          <div className={style["values"]}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <button onClick={handleHeartClick}>
                  <img src={likes} alt="likes" className={style["likes"]} />
                </button>
                <span>{formatNumber(likesValue.length)}</span>
              </div>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <img src={comments} alt="comments" className={style["likes"]} />
                <span>{formatNumber(commentsValue.length)}</span>
              </div>
            </div>

            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <FacebookShareButton
                url={`${process.env.REACT_APP_API_URL}/video-details`}
                // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData._id}`}
                title={`${videoData?.product?.name} - Recolyse`}
              >
                <FacebookIcon borderRadius={100} size={30} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={`${process.env.REACT_APP_API_URL}/video-details`} 
                // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData._id}`}
                title={`${videoData?.product?.name} - Recolyse`}
              >
                <LinkedinIcon borderRadius={100} size={30} />
              </LinkedinShareButton>
              <TwitterShareButton
                url={`${process.env.REACT_APP_API_URL}/video-details`}
                // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData?._id}`}
                title={`${videoData?.product?.name} - Recolyse`}
              >
                <img
                  src={twitter_icon}
                  alt="twitter icon"
                  width={32}
                  style={{ minWidth: "32px" }}
                />
              </TwitterShareButton>
              <WhatsappShareButton
                url={`${process.env.REACT_APP_API_URL}/video-details`}
                // url={`https://recolyse.com/${videoData?.product?.productId}/video/${videoData._id}`}
                title={`${videoData?.product?.name} - Recolyse`}
              >
                <WhatsappIcon borderRadius={100} size={30} />
              </WhatsappShareButton>
            </div>
          </div>

          <div className={style["copy-container"]}>
            <span>{generateUrl()}</span>
            <button onClick={handleCopyLink}>Copy link</button>
          </div>

          <span
            style={{
              textDecoration: "underline",
              fontWeight: "600",
            }}
          >
            Comments
          </span>
          <Comment
            videoId={videoData._id}
            productId={videoData?.product?._id}
            onCommentAdded={updateCommentCount}
            onCommentDeleted={handleCommentDeleted}
          ></Comment>
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default VideoDetailsModal;

// const fetchVideosByType = async (type, page = 1, limit = 9) => {
//   try {
//     const res = await api.get(
//       `/videos/filterByCategory?page=${page}&limit=${limit}&category=${encodeURIComponent(
//         type
//       )}`
//     );
//     console.log(res);
//     // setAllVideos(res.videos);
//     setDisplayedVideos(res.data.videos?.slice(0, videosPerLoad));
//     return res;
//   } catch (error) {
//     console.error("Error fetching videos by type:", error);
//     throw error;
//   }
// };

// useEffect(() => {
//   choosenType && choosenType !== "All"
//     ? fetchVideosByType(choosenType, 1)
//     : fetchVideosByType("", 1);
// }, [choosenType]);
