import React, { useState, useEffect } from "react";
import ProductComments from "./ProductComments";
import { Rating } from "@mui/material";
import { useUser } from "../../../state/UserContext";
import api from "../../../Intercepters/AuthIntercepter";
import style from "./productDetails.module.scss";
import { useNavigate } from "react-router-dom";

export default function ProductReviews({ product, ratings, setParentRatings }) {
  const { userData } = useUser();
  const [myRatingId, setMyRatingId] = useState(null);
  const [localRatings, setLocalRatings] = useState(ratings);
  const [value, setValue] = useState(0);
  const [myValue, setMyValue] = useState(0);
  const [count5Stars, setCount5Stars] = useState(0);
  const [count4Stars, setCount4Stars] = useState(0);
  const [count3Stars, setCount3Stars] = useState(0);
  const [count2Stars, setCount2Stars] = useState(0);
  const [count1Stars, setCount1Stars] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (localRatings.length > 0) {
      let sum = 0;
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;
      let count4 = 0;
      let count5 = 0;

      for (const rate of localRatings) {
        sum += rate.value;
        switch (Math.round(rate.value)) {
          case 5:
            count5 += 1;
            break;
          case 4:
            count4 += 1;
            break;
          case 3:
            count3 += 1;
            break;
          case 2:
            count2 += 1;
            break;
          case 1:
            count1 += 1;
            break;
          default:
            break;
        }

        if (userData && rate?.user?._id == userData?._id) {
          setMyValue(rate?.value);
          setMyRatingId(rate?._id);
        }
      }
      setCount1Stars(count1);
      setCount2Stars(count2);
      setCount3Stars(count3);
      setCount4Stars(count4);
      setCount5Stars(count5);
      setValue(sum / localRatings.length);
    }
  }, [localRatings]);

  const handleRateChange = async (event, val) => {
    if (userData) {
      if (val) {
        if (myRatingId) {
          try {
            await api.put(`/ratings/update/${myRatingId}`, {
              userId: userData?._id,
              value: val,
            });
            const updatedRatings = localRatings.map((e) =>
              e._id === myRatingId ? { ...e, value: val } : e
            );
            setMyValue(val);
            setLocalRatings(updatedRatings);
            setParentRatings(updatedRatings); // Pass the full updated ratings
          } catch (error) {
            console.error("Error updating my rate:", error);
          }
        } else {
          try {
            const res = await api.post(
              `/ratings/add-ratings-to-product/${product._id}`,
              {
                userId: userData?._id,
                value: val,
              }
            );
            const newRating = res.data;
            const updatedRatings = [...localRatings, newRating];
            setMyValue(val);
            setMyRatingId(newRating?._id);
            setLocalRatings(updatedRatings);
            setParentRatings(updatedRatings); // Pass the full updated ratings
          } catch (error) {
            console.error("Error submitting new rate:", error);
          }
        }
      }
    } else {
      localStorage.setItem("redirectAfterLogin", window.location.pathname);
      navigate("/login");
    }
  };
  const fetchRating = async () => {
    try {
      const res = await api.get(`/ratings/get-ratings/${product?._id}`);

      if (res?.data) {
        setLocalRatings(res.data);
      }
    } catch {}
  };
  useEffect(() => {
    async function getRating() {
      await fetchRating();
    }
    getRating();
  }, []);
  return (
    <div>
      <div className={style["review-page"]}>
        <div className={style["top-section"]}>
          <p className={style["question"]}>
            What do people think of {product?.name}?
          </p>
          <p className={style["description"]}>
            The community submitted {product?.comments.length} reviews to tell
            us what they like about {product?.name}, what {product?.name} can do
            better, and more.
          </p>
        </div>
        <div className={style["review-box"]}>
          <div className={style["box"]}>
            <p className={style["box-header"]}>
              What do you think about {product?.name}?
            </p>
            <p className={style["box-description"]}>
              Leave a rating or review for the community
            </p>
          </div>
          <Rating value={myValue} onChange={handleRateChange} />
        </div>
        <div className={style["rating-container"]}>
          <div className={style["rating-left"]}>
            <div className={style["rating-value"]}>{value.toFixed(1)}/5</div>
            <div className={style["based"]}>
              Based on {localRatings?.length} ratings
            </div>
          </div>
          <div className={style["rating-right"]}>
            <div className={style["rating-div"]}>
              <Rating name="size-medium" defaultValue={5} readOnly={true} />{" "}
              <span className={style["ratings"]}>{count5Stars} ratings</span>
            </div>
            <div className={style["rating-div"]}>
              <Rating name="size-medium" defaultValue={4} readOnly={true} />{" "}
              <span className={style["ratings"]}>{count4Stars} ratings</span>
            </div>
            <div className={style["rating-div"]}>
              <Rating name="size-medium" defaultValue={3} readOnly={true} />{" "}
              <span className={style["ratings"]}>{count3Stars} ratings</span>
            </div>
            <div className={style["rating-div"]}>
              <Rating name="size-medium" defaultValue={2} readOnly={true} />{" "}
              <span className={style["ratings"]}>{count2Stars} ratings</span>
            </div>
            <div className={style["rating-div"]}>
              <Rating name="size-medium" defaultValue={1} readOnly={true} />{" "}
              <span className={style["ratings"]}>{count1Stars} ratings</span>
            </div>
          </div>
        </div>
        <ProductComments product={product}></ProductComments>
      </div>
    </div>
  );
}
