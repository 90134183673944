import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { robo, sound_icon, sound_off_icon } from "../../../assets/images";
import style from "./listProducts.module.scss";
import { defaultImage } from "../../../assets/images";
import Hls from "hls.js";
import { CircularProgress } from "@mui/material";
import { Skeleton } from "@mui/material";

const ProductCard = ({
  product,
  myProductsRatings,
  userData,
  setOpenLoginPopUp,
  lastProductElementRef,
  isMuted,
  toggleMute,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [duration, setDuration] = useState(null);
  const videoRef = useRef(null);
  const [hls, setHls] = useState(null);
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleCardClick = (productId) => {
    navigate(`/${product.productId}/${productId}`);
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
    }
  };

  useEffect(() => {
    if (videoRef.current && isHovered && !isPlaying) {
      videoRef.current.currentTime = 0;
      videoRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => {});
    } else if (videoRef.current && !isHovered && isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [isHovered]);

  let pauseTimeout;
  let playTimeout;

  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    // Simulate data fetching for skeleton loading
    setTimeout(() => setIsLoadingData(false), 500);
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsLoadingVideo(true);

    setIsVideoEnded(false);
    clearTimeout(pauseTimeout);

    if (hls && isVideoEnded && videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(() => {});
    } else if (hls && videoRef.current && videoRef.current.paused) {
      videoRef.current.play()?.catch(() => {});
    } else if (Hls.isSupported() && videoRef.current) {
      const newHls = new Hls();
      newHls.loadSource(product.pin_video.videoURL);
      newHls.attachMedia(videoRef.current);
      newHls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current.play().catch(() => {});
      });
      setHls(newHls);
    } else if (videoRef.current?.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = `${process.env.REACT_APP_API_URL}/videos/${product.pin_video._id}/output.m3u8`;
      videoRef.current.addEventListener("loadedmetadata", () => {
        videoRef.current.play().catch(() => {});
      });
    }

    videoRef.current.addEventListener("canplaythrough", () => {
      setIsLoadingVideo(false);
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current && isPlaying) {
      videoRef.current
        .pause()
        ?.catch((error) => console.error("Error pausing video:", error));
      setIsPlaying(false);
    }
  };

  const handleVideoEnded = () => {
    setIsVideoEnded(true);
    setIsPlaying(false);
  };

  const LoadingIndicator = () => (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        pointerEvents: "none",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <div
      key={product._id}
      className={style.product}
      ref={lastProductElementRef}
    >
      <div className={style["video-player-container"]}>
        {product.pin_video ? (
          <div
            className={`${style["video-player"]} ${
              isHovered ? `${style["active"]}` : ""
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isLoadingData ? (
              <Skeleton variant="rectangular" width="100%" height={250} />
            ) : (
              <img
                style={{
                  display: !isHovered || isLoadingVideo ? "block" : "none",
                  maxHeight: 250,
                }}
                className={style["imgVideo"]}
                src={product.pin_video.capture}
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = robo;
                }}
                alt="Loading"
                onClick={() => handleCardClick(product._id)}
              />
            )}

            <div
              className={style["blurred-background"]}
              style={{
                backgroundImage: `url(${product.pin_video.capture})`,
              }}
            ></div>

            {isLoadingVideo && isHovered && (
              <LoadingIndicator
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000000,
                }}
              />
            )}

            <video
              style={{
                display: isHovered && !isLoadingVideo ? "block" : "none",
              }}
              ref={videoRef}
              // src={product.pin_video.videoURL}
              // controls={isHovered}
              className={style["video"]}
              // poster={product.pin_video.capture}
              muted={isMuted}
              onLoadedMetadata={handleLoadedMetadata}
              onEnded={handleVideoEnded}
              onClick={() => handleCardClick(product._id)}
            />

            {isHovered && (
              <div
                id="mute"
                style={{
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  zIndex: "1000000",
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                }}
                onClick={() => toggleMute()}
              >
                <img
                  src={isMuted ? sound_off_icon : sound_icon}
                  alt="sound"
                  id="mute-icon"
                  style={{
                    height: "25px",
                    width: "25px",
                    minHeight: "25px",
                    minWidth: "25px",
                    maxHeight: "25px",
                    maxWidth: "25px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}

            {isHovered && duration && (
              <div
                className={style["video-duration"]}
                style={{
                  position: "absolute",
                  left: "10px",
                  bottom: "10px",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                  zIndex: "100",
                }}
              >
                {Math.floor(duration / 60)}:
                {("0" + Math.floor(duration % 60)).slice(-2)}
              </div>
            )}
          </div>
        ) : (
          <img src={defaultImage} alt="product" />
        )}
      </div>

      <div className={style["product-info"]}>
        <div className={style["product-info-left"]}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className={style["product-image"]}>
                <div
                  className={style["image"]}
                  onClick={() => handleCardClick(product._id)}
                >
                  {isLoadingData ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : product.image ? (
                    <Link to={`/${product.productId}/${product._id}`}>
                      <img
                        src={
                          product.image.includes("upload")
                            ? `${process.env.REACT_APP_API_URL}/${product.image}`
                            : product.image
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = robo;
                        }}
                        alt="product"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>
                  ) : (
                    <Link to={`/${product.productId}/${product._id}`}>
                      <img src={robo} alt="product placeholder" />
                    </Link>
                  )}
                </div>
              </div>

              <span className={style["product-desc"]}>
                {isLoadingData ? (
                  <Skeleton variant="text" width="80%" />
                ) : product?.description ? (
                  product?.description
                ) : (
                  "..."
                )}
              </span>
              {/* <Rating
              value={
                myProductsRatings.find((productRating) => productRating.product === product._id)?.rating || 0
              }
              readOnly
              precision={0.5}
            /> */}
            </div>
            <span className={style["product-title"]}>
              {" "}
              {isLoadingData ? (
                <Skeleton variant="text" width="80%" />
              ) : product.name.length > 20 ? (
                product.name.slice(0, 20) + "..."
              ) : (
                product.name
              )}
            </span>
          </div>
        </div>

        <div className={style["product-details"]}>
          <button
            onClick={() => handleCardClick(product._id)}
            className={style["button-get"]}
          >
            Get
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
