import React, { useState } from "react";
import Card from "../card/Card.jsx";
import style from "./cardGrid.module.scss";
import { useNavigate } from "react-router-dom";
import LoginPopUp from "../../pages/auth/login-page/LoginPopUp.jsx";
import { useUser } from "../../state/UserContext.jsx";
import VideoDetailsModal from "./VideoDetailsModal.jsx";

const CardGrid = (props) => {
  const {
    videosData,
    requireLogin,
    lastVideoElementRef,
    selectedVideoIndex,
    setSelectedVideoIndex,
  } = props;
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleCardClick = (videoData, index) => {
    // if (!userData) {
    //   setOpenLoginPopUp(true);
    //   return;
    // } else {
    // navigate(`/video-details/${videoId}`);
    setSelectedVideo(videoData);
    setSelectedVideoIndex(index);
    setIsModalOpen(true);
    // }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  return (
    <div className={style["grid"]}>
      {videosData.map((data, index) => {
        if (videosData.length === index + 1) {
          return (
            <Card
              key={data._id}
              videoLikes={data?.likes || []}
              productName={data.product.name}
              videoPreview={data.videoURL}
              productImage={data.product.image}
              videoCapture={data.capture}
              title={data.title}
              textPreview={{
                plan: data.category,
                dateOfPost: data.createdAt,
                productOwner:
                  data.product.business_owner.firstName +
                  " " +
                  data.product.business_owner.lastName,
              }}
              productId={data.product._id}
              productIdentifier={data.product?.productId}
              videoId={data._id}
              openVideoDisplay={() => handleCardClick(data, index)}
              index={index}
              isMuted={isMuted}
              toggleMute={() => setIsMuted(!isMuted)}
              lastVideoElementRef={lastVideoElementRef}
              date={data.created_at}
              setCurrentPlayingVideo={setCurrentPlayingVideo}
              currentPlayingVideo={currentPlayingVideo}
            ></Card>
          );
        } else {
          return (
            <Card
              key={data._id}
              videoLikes={data?.likes || []}
              productName={data.product?.name}
              videoPreview={data?.videoURL}
              productImage={data?.product?.image}
              videoCapture={data?.capture}
              title={data.title}
              textPreview={{
                plan: data?.category,
                dateOfPost: data?.createdAt,
                productOwner: `${data?.product?.business_owner?.firstName} ${data?.product?.business_owner?.lastName}`,
              }}
              productId={data?.product?._id}
              productIdentifier={data.product?.productId}
              videoId={data?._id}
              openVideoDisplay={() => handleCardClick(data, index)}
              index={index}
              isMuted={isMuted}
              toggleMute={() => setIsMuted(!isMuted)}
              date={data?.updated_at}
              setCurrentPlayingVideo={setCurrentPlayingVideo}
              currentPlayingVideo={currentPlayingVideo}
            />
          );
        }
      })}

      {selectedVideo && (
        <VideoDetailsModal
          open={isModalOpen}
          onClose={handleCloseModal}
          videoData={selectedVideo}
          videosData={videosData}
          setSelectedVideo={setSelectedVideo}
          selectedVideoIndex={selectedVideoIndex}
          setSelectedVideoIndex={setSelectedVideoIndex}
        />
      )}

      <LoginPopUp
        open={openLoginPopUp}
        handleClose={() => setOpenLoginPopUp(false)}
      />
    </div>
  );
};

export default CardGrid;
