import "./App.css";
import LoginPage from "./pages/auth/login-page/LoginPage";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import SignupPage from "./pages/auth/signup-page/SignupPage";
import Board from "./pages/dashboard/board/UserBoard";
import Page404 from "./pages/dashboard/page-404/Page404";
import { UserProvider } from "./state/UserContext";
import ListProduct from "./pages/dashboard/products-list/ListProduct";
import VideoPage from "./pages/dashboard/video-page/VideoPage";

import ProductDetails from "./pages/dashboard/product-details/ProductDetails";
import UploadVideo from "./pages/dashboard/upload-video/UploadVideo";
import { VideoProvider } from "./state/VideoContext";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import Support from "./pages/Support/support";
import PrivateRoute from "./state/PrivateRoute";
import { HelmetProvider } from "react-helmet-async";
import { RecordingProvider } from "./state/RecordingContext";
import { CircularProgress } from "@mui/material";
import ActivationPage from "./pages/auth/signup-page/ActivationPage";
import SignedPage from "./pages/auth/signup-page/SignedPage";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPasswordPage";
import ResetPassword from "./pages/auth/forgot-password/ResetPassword";
import VideoDetails from "./pages/video-details/VideoDetails";

const MySpace = lazy(() => import("./pages/dashboard/my-space/MySpace"));
const Profile = lazy(() =>
  import("./pages/dashboard/profile-settings/Profile")
);

function App() {
  return (
    <div className="App h-screen">
      <HelmetProvider>
        <BrowserRouter>
          <UserProvider>
            <VideoProvider>
              <RecordingProvider>
                <Suspense fallback={<CircularProgress size={24} />}>
                  <Routes>
                    <Route path="/" element={<Layout page={<Board />} />} />

                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route
                      path="/confirm/:verificationCode"
                      element={<ActivationPage />}
                    />
                    <Route path="/success" element={<SignedPage />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/reset-password/:resetToken"
                      element={<ResetPassword />}
                    />
                    <Route
                      path="/products-store"
                      element={<Layout page={<ListProduct />} />}
                    />
                    <Route
                      path="/my-space"
                      element={<Layout page={<MySpace />} />}
                    />

                    <Route
                      path="/:productName/:id"
                      element={<Layout page={<ProductDetails />} />}
                    />

                    {/* <Route
                      path="/:productName/video/:id"
                      element={<Layout page={<VideoDetails />} />}
                    /> */}

                    <Route
                      path="/settings/profile"
                      element={<Layout page={<Profile />} />}
                    />
                    <Route
                      path="/video"
                      element={<Layout page={<VideoPage />} />}
                    />
                    <Route
                      path="/upload-video"
                      element={<Layout page={<UploadVideo />} />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                    <Route path="/support" element={<Support />} />

                    {/* Not Found Page */}
                    <Route path="*" element={<Page404 />} />

                    {/* <Route
                      path="/matchmaking-demo"
                      element={
                        // <PrivateRoute>
                        <Layout page={<Matchmaking />} />
                        // </PrivateRoute>
                      }
                    /> */}
                    {/* Not used routes */}
                    {/* <Route path="/" element={<LandingPage />} /> */}
                    {/* <Route path="/" element={<HomePageV2 />} /> */}
                    {/* <Route path="/record-exemple" element={<RecordPageExample />} /> */}
                    {/* <Route path="/board" element={<Layout page={<Board />} />} /> */}
                    {/* <Route path="/analytics" element={<Layout page={<Analytics />} />} /> */}
                    {/* <Route path="/reports" element={<Layout page={<Reports />} />} />*/}
                    {/* <Route path="/action-log" element={<Layout page={<UserActionLog />} />} /> */}
                    {/* <Route path="/saved-posts" element={<Layout page={<SavedPosts />} />} /> */}
                    {/* <Route path="/saved-products" element={<Layout page={<SavedProducts />} />} /> */}
                    {/* <Route path="/schedule" element={<Layout page={<Schedule />} />} /> */}
                    {/* <Route path="/wishlist" element={<Layout page={<Wishlist />} />} /> */}
                    {/* <Route path="/switch-to-businessowner" element={<Layout page={<SwitchToBusinessOwner />} />} /> */}
                    {/* <Route path="/camera" element={<CameraComponent />} /> */}
                    {/* <Route path="/settings/team" element={<Layout page={<Team />} />} /> */}
                    {/* <Route path="/settings/edit-profile" element={<Layout page={<EditProfile />} />} /> */}
                    {/* <Route path="/create-product" element={<Layout page={<CreateProduct />} />} /> */}
                  </Routes>
                </Suspense>
              </RecordingProvider>
            </VideoProvider>
          </UserProvider>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
